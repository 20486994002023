<template>
  <div class="auth">
    <div class="auth-card">
      <div class="auth-card__logo">
        <img
          src="@/assets/img/logoBSL-black.svg"
          alt="BSL"
        >
      </div>
      <h1 class="auth-card__title mt-6">Авторизация</h1>

      <div
        class="prompt"
        v-if="showPrompt"
      >
        <div class="tit">Для защиты ваших данных необходимо придумать безопасный пароль. Он должен содержать:</div>

        <div class="list">
          <div class="item">8 и более символов</div>
          <div class="item">прописные латинские буквы</div>
          <div class="item">строчные латинские буквы</div>
          <div class="item">цифры</div>
        </div>
      </div>

      <div class="auth-card__subtitle mt-5">Придумайте надежный пароль, состоящий из букв, цифр и других символов</div>
      <v-form
        ref="form"
        v-model="isValid"
        lazy-validation
        @submit.prevent="submit"
        class="mt-6"
      >
        <v-text-field
          v-model="form.password"
          :rules="passwordRules"
          label="Пароль"
          class="rounded-lg"
          @click:append="showPassword = !showPassword"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPassword ? 'text' : 'password'"
          outlined
          rounded
          @focus="showPrompt = false"
          @mouseover="showPrompt = true"
          @mouseleave="showPrompt = false"
        />

        <v-text-field
          v-model="form.confirmPassword"
          :rules="confirmPasswordRules"
          label="Подтвердите пароль"
          class="rounded-lg"
          @click:append="showConfirmPassword = !showConfirmPassword"
          :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showConfirmPassword ? 'text' : 'password'"
          outlined
          rounded
        />

        <v-btn
          color="black"
          dark
          x-large
          class="modal__btn"
          type="submit"
        >
          Далее
        </v-btn>
      </v-form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { isEqual } from '@/core/validationHelpers';

export default {
  // https://bslpln.bsl.dev/restore/id-сотрудника/token?register=1

  name: 'Restore',
  data: () => ({
    isValid: true,
    showPassword: false,
    showConfirmPassword: false,
    form: {
      password: '',
      confirmPassword: '',
    },
    showPrompt: false,
  }),

  computed: {
    ...mapGetters({
      loading: 'auth/loading',
    }),
    confirmPasswordRules() {
      return isEqual(this.form.password);
    },
  },

  methods: {
    ...mapActions({
      passwordReset: 'auth/passwordReset',
    }),
    async submit() {
      if (this.loading) return;
      await this.$refs.form.validate();
      if (!this.isValid) return;
      const { userId } = this.$route.params;
      const { token } = this.$route.params;
      const data = {
        user_id: userId,
        token,
        password: this.form.password,
      };
      this.passwordReset(data).then(() => {
        this.$router.push({ name: 'Login' });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.prompt {
  position: fixed;
  margin-top: 10px;
  margin-left: 250px;
  width: 340px;
  height: 190px;
  background: rgba(97, 97, 97, 0.9);
  padding: 15px;
  z-index: 1000;
  border-radius: 4px;

  .tit {
    font-size: 14px;
    letter-spacing: 0.1px;
    color: #ffffff;
  }
  .list {
    margin-top: 20px;
    .item {
      &::before {
        content: "";
        display: inline-block;
        position: relative;
        top: -3px;
        width: 3px;
        height: 3px;
        margin-right: 10px;
        border-radius: 50%;
        background: #ffffff;
      }
      margin-left: 10px;
      font-size: 14px;
      letter-spacing: 0.1px;
      color: #ffffff;
    }
  }
}
::v-deep .v-messages__message {
  color: #ff5252;
  font-size: 12px;
  bottom: 1px;
  left: -10px;
  position: relative;
  z-index: 100;
}
</style>
